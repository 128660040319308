import axios from 'axios';

const apiUrl = "https://insuapi.projectcenter.cloud/api/admin/v1/";
let access_token =
            localStorage.getItem("access_token") !== "" &&
            localStorage.getItem("access_token") !== null &&
            localStorage.getItem("access_token") !== undefined
                ? localStorage.getItem("access_token")
                : "";
axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

const Environment = {
    get(action, object) {
        let userId =
            localStorage.getItem("userId") !== "" &&
            localStorage.getItem("userId") !== null &&
            localStorage.getItem("userId") !== undefined
                ? localStorage.getItem("userId")
                : "";

    const url = apiUrl + action;
        let formData = new FormData();
        
        var headers = {
          'Accept': 'application/json',
        }
        return axios.get(url, { params:object }, {headers: headers}).then(response => {
            if (response.data.message == "Unauthenticated") {
              setTimeout(() => {
                  window.location = "/logout";
              }, 1000);
            }
            return response;
          });
    },
    post(action, object) {
        let userId =
            localStorage.getItem("userId") !== "" &&
            localStorage.getItem("userId") !== null &&
            localStorage.getItem("userId") !== undefined
                ? localStorage.getItem("userId")
                : "";

        const url = apiUrl + action;

        var headers = {
        }
        // By Default Id
        object.admin_user_id = userId;

      return axios.post(url, object, {headers: headers}).then(response => {
        if (response.data.message == "Unauthenticated") {
          setTimeout(() => {
              window.location = "/logout";
          }, 1000);
        }
        console.log(response)
        return response;
      });
    },
    put(action, object) {
        let userId =
            localStorage.getItem("userId") !== "" &&
            localStorage.getItem("userId") !== null &&
            localStorage.getItem("userId") !== undefined
                ? localStorage.getItem("userId")
                : "";

        const url = apiUrl + action;

        var headers = {
        }
        // By Default Id
        object.admin_user_id = userId;

      return axios.put(url, object, {headers: headers}).then(response => {
        if (response.data.message == "Unauthenticated") {
          setTimeout(() => {
              window.location = "/logout";
          }, 1000);
        }
        console.log(response)
        return response;
      });
    },
    delete(action, object) {
        let userId =
            localStorage.getItem("userId") !== "" &&
            localStorage.getItem("userId") !== null &&
            localStorage.getItem("userId") !== undefined
                ? localStorage.getItem("userId")
                : "";

        const url = apiUrl + action;

        var headers = {
        }
        // By Default Id
        object.admin_user_id = userId;

      return axios.delete(url, {headers: headers, data:object}).then(response => {
        if (response.data.message == "Unauthenticated") {
          setTimeout(() => {
              window.location = "/logout";
          }, 1000);
        }
        console.log(response)
        return response;
      });
    }
};

export default Environment;

