import React, { Component } from "react";
import api from '../../helper/Api';
import { toast } from 'react-toastify';
import Loading from 'react-fullscreen-loading';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60%",
    minWidth: 400,
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: '12px',
    boxShadow: 24,
};
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
const sortIcon = <ArrowDownward />;
const customStyles = {
    rows: {
        style: {
        },
    },
    headCells: {
        style: {
          backgroundColor: "#dbdbdb",
          fontWeight: "bold",
        },
    },
    cells: {
        style: {
        },
    },
};
class Setting extends Component {
  constructor(props) {
    super(props);
    const columns = [
      {
        name: 'Sr. No.',
        selector: row => row.sr_no,
        sortable: true,
        maxWidth: '100px',
        wrap: true,
      },
      {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        wrap: true,
      },
      {
        name: 'Value',
        selector: row => row.val,
        sortable: true,
        wrap: true,
      },
      {
        name: 'Action',
        selector: row => row.action,
        // sortable: true,
        wrap: true,
      },
    ];
    this.state = {
      loading: true,
      open: false,
      user: JSON.parse(localStorage.getItem('userData')),
      master_name: this.props.params.name,
      columns: columns,
      master_data: [],
    }
  }
  componentDidMount() {
    this.callApi();
  }
  handleChange = ({ currentTarget: input }) => {
    this.setState({ 
      [input.name]:input.value
     });
  }
  editSetting = (id,name,val,status) => {
    this.setState({open:true,status:status,master_id:id,name:name,val:val})
  }
  handleClose = () => {
      this.setState({open:false})
  }
  submitForm = () => {
    var context = this;
    if (context.state.name == '') {
      toast.error("Please Enter Name!");
      return false;
    }
    if (context.state.val == '') {
      toast.error("Please Enter Value!");
      return false;
    }
    context.setState({ loading: true });
    var data = {};
    data.master_name = (context.state.master_name == "AppSettings"?"app_settings":(context.state.master_name == "WebSettings"?"web_settings":""));
    data.master_id = context.state.master_id;
    data.data = {
      "name" : context.state.name,
      "val" : context.state.val,
    };
    if (context.state.status == 1) {
      api.post("masters",data)
        .then(response => {
          try {
            if (response.data.error) {
              toast.error(response.data.message);
            } else {
              toast.success(response.data.message);
              context.setState({ loading: true, open: false });
              this.callApi();
            }
          } catch (error) {
          }
        });
    } else {
      api.put("masters",data)
        .then(response => {
          try {
            if (response.data.error) {
              toast.error(response.data.message);
            } else {
              toast.success(response.data.message);
              context.setState({ loading: true, open: false });
              this.callApi();
            }
          } catch (error) {
          }
        });
    }
  }
  deleteSetting = (id) => {
    var context = this;
    if (window.confirm("Delete the item?")) {
      var data = {};
      data.master_name = (context.state.master_name == "AppSettings"?"app_settings":(context.state.master_name == "WebSettings"?"web_settings":""));
      data.master_id = id;
      api.delete("masters",data)
        .then(response => {
          try {
            if (response.data.error) {
              toast.error(response.data.message);
            } else {
              toast.success(response.data.message);
              context.setState({ loading: true, open: false });
              this.callApi();
            }
          } catch (error) {
          }
        });
    }
  }
  callApi = () => {
    var context = this;
    context.setState({ loading: true });
    api.get("masters",{master_name:context.state.master_name})
      .then(response => {
        try {
          if (response.data.error) {
            toast.error(response.data.message)
          } else {
            var master_data = [];
            for (let index = 0; index < response.data.length; index++) {
              const element = response.data[index];
              master_data.push({
                sr_no: index + 1,
                id: element.id,
                name: element.name,
                val: element.val,
                action: <div>
                  <button onClick={() => { this.editSetting(element.id, element.name, element.val, 2) }} className="btn btn-info btn-circle"><FontAwesomeIcon icon={icon({ name: "pencil" })} /></button>
                  <button onClick={() => { this.deleteSetting(element.id) }} className="btn btn-warning btn-circle ml-1"><FontAwesomeIcon icon={icon({name: "trash"})} /></button>
                </div>,
              })
            }
            console.log(master_data)
            context.setState({ master_data: master_data, loading: false });
          }
        } catch (error) {
        }
      });
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <div className="row">
              <h1 className="h3 m-0 font-weight-bold text-primary col">{this.state.master_name}</h1>
              <button onClick={() => { this.editSetting(0, '', '',1) }} className="btn btn-primary btn-icon-split">
                  <span className="icon text-white-50">
                      <FontAwesomeIcon icon={icon({ name: "plus" })} />
                  </span>
                  <span className="text">Setting</span>
                </button>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <DataTable
                pagination
                responsive
                sortIcon={sortIcon}
                columns={this.state.columns}
                data={this.state.master_data}
                defaultSortFieldId={1}
                striped
                customStyles={customStyles}
              />
            </div>
          </div>
        </div>

        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{zIndex:1}}
        >
          <Box sx={style}>
            <div style={{ padding: "10px", borderBottom: "1px solid rgb(1, 46, 93)" }}>
                <div className="d-flex">
                  <h5 style={{ flex: 1, marginBottom: "0px", color: "rgb(1, 46, 93)", textAlign: "center" }}>
                  {this.state.status == 1 ? "Add Details" : 'Edit Details'}
                  </h5>
                  <button style={{ background: "none", border: "none", width: "fit-content", padding: "0px", color: "red" }} onClick={this.handleClose}>
                      <img style={{width: "24px"}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVR4nO2Z3UrDMBTH81Amft01012oCOKt+BAqKA4miCJ44Y0PIIj4id6IdzrfwyFDbzargjYbrZVFWtexuXY2TZN0kD+cu5X9fzkn56QNAFpaWlqZVh2jWWKge8tAhGBEZYTl/ZcBS3VjaIbLvIXRjizTJAoGw63kK6/YPGkHnGYG8MomQwB37AAYWhkC+EwAoNo06goNQKJWJz9GSW54QDOQH6ffN9fUPT1ihrALK36oA2iZp+a7HywQ9voSpVWT0tortTfW1AC4Z8dt80F8HR78C9E2HzxXiweROkBjYY42H8o9EP0y0WPebAEUV9XsARYIHvNEZBuNA8FrnoieA/0g7MIyt3kiY5BFQdCXN27zRNYkjoTgNE9kHiV8iMpTaCbsYryeT1QC+Bu29qfmgz1xwj6xiUyA0G6TEgQQDRDVKpuV51QggEiAfn0+ssUyQgBRAHGGVBoQQAQAy4TlhQBpAyQ5HjQW52mz/Bh+isWSAdyri24jVTPWC0pYJpz9PQUlNDFC3cvzRBO2E8KJYV7cJp4c9TPB8mrYWU7O7nbs34sBkBhAA+CBywDMzqdFA36wAxiwpNw4DgLeMgN4lwvqjaPfyKEpkETe5YJq85aBNgGPvMsF7/u83D0BLa9sEq+8lpaWFpClH85QF54vuUIVAAAAAElFTkSuQmCC"/>
                  </button>
                </div>
            </div>
            <main className="container" style={{ maxWidth: "100%", marginTop: 10, marginBottom: 10 }}>
              <div className="row p-1 g-3 align_item_center">
                <label for="name" className="form-label">Name</label>
                <input type="text" style={{ marginLeft: 10 }} className="form-control col" id="name" name="name" placeholder="" value={this.state.name} onChange={this.handleChange} />
              </div>
              <div className="row p-1 g-3 align_item_center">
                <label for="val" className="form-label">Value</label>
                <input type="text" style={{ marginLeft: 10 }} className="form-control col" id="val" name="val" placeholder="" value={this.state.val} onChange={this.handleChange} />
              </div>
              <div className="row pb-0 align_item_center" style={{justifyContent: "center", marginTop: 10}}>
                  <button className="btn btn-primary btn-lg" style={{ width: "fit-content", borderRadius: "24px", padding: "12px 26px", color: "white", background: "#4caf50", border: "white", fontWeight: 700, fontSize: "13px" }}
                      onClick={ this.submitForm }>Submit</button>
              </div>
            </main>
          </Box>
        </Modal>
        { this.state.loading?<Loading loading background="#ffffff80" loaderColor="rgb(76 175 80)" />:null}
      </div>
    );
  }
}

export default withParams(Setting);
