import React, { Component } from "react";
import api from '../../helper/Api';
import { toast } from 'react-toastify';
import Loading from 'react-fullscreen-loading';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      user: JSON.parse(localStorage.getItem('userData')),
      dashboard_counts: []
    }
  }
  componentDidMount() {
    var context = this;
    api.get("dashboard")
      .then(response => {
        try {
          if (response.data.error) {
            toast.error(response.data.message)
          } else {
            var dashboard_counts = response.data.counts;
            var colors = ['primary', 'success', 'info'];
            var index1 = 0;
            for (let index = 0; index < dashboard_counts.length; index++) {
              if (index1 == 3) {
                index1 = 0
              }
              dashboard_counts[index]['color'] = colors[index1];
              index1++;
            }
            context.setState({dashboard_counts:dashboard_counts,loading:false });
          }
        } catch (error) {
        }
      });
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
        </div>
        {this.state.dashboard_counts.length <= 0 ? (
            <div>
            </div>
        ) : (
          <div className="row">
            {
              this.state.dashboard_counts.map(dashboard_counts => (
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className={`card border-left-${dashboard_counts.color} shadow h-100 py-2`}>
                      <div className="card-body">
                          <div className="row no-gutters align-items-center">
                              <div className="col mr-2">
                          <div className={`text-xs font-weight-bold text-${dashboard_counts.color} text-uppercase mb-1`}>
                                  {dashboard_counts.name}
                                </div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard_counts.count}</div>
                              </div>
                              <div className="col-auto">
                                  <i className="fas fa-calendar fa-2x text-gray-300"></i>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              ))
            }
          </div>
        )}
        { this.state.loading?<Loading loading background="#ffffff80" loaderColor="rgb(76 175 80)" />:null}
      </div>
    );
  }
}
export default Dashboard;
