import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Dropdown from 'react-bootstrap/Dropdown';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        var route_dashboard = false;
        var route_insurance = false;
        var route_claim = false;
        var route_master = false;
        var route_settings = false;
        var pathname = window.location.pathname;
        if (pathname.includes("insurance")) {
            route_insurance = true;
        }else if (pathname.includes("claim")) {
            route_claim = true;
        } else if (pathname.includes("masters")) {
            route_master = true;
        } else if (pathname.includes("settings")) {
            route_settings = true;
        } else {
            route_dashboard = true;
        }
        this.state = {
            route_dashboard: route_dashboard,
            route_insurance: route_insurance,
            route_claim: route_claim,
            route_master: route_master,
            route_settings: route_settings,
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                <a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                    <div className="sidebar-brand-icon rotate-n-15">
                        <FontAwesomeIcon icon={icon({name: "laugh-wink"})} />
                    </div>
                    <div className="sidebar-brand-text mx-3">Alya</div>
                </a>
                <hr className="sidebar-divider my-0"/>
                <li className={"nav-item " + (this.state.route_dashboard == true ?"active":"")}>
                    <a className="nav-link" href="/">
                        <FontAwesomeIcon icon={icon({name: "tachometer-alt"})} />
                        <span>Dashboard</span>
                    </a>
                </li>
                <li className={"nav-item " + (this.state.route_insurance == true ?"active":"")}>
                    <a className="nav-link" href="/insurance">
                        <FontAwesomeIcon icon={icon({ name: "calendar" })} />
                        <span>Insurance</span>
                    </a>
                </li>
                <li className={"nav-item " + (this.state.route_claim == true ?"active":"")}>
                    <a className="nav-link" href="/claim">
                        <FontAwesomeIcon icon={icon({name: "clock"})} />
                        <span>Claim</span>
                    </a>
                </li>
                <Dropdown data-bs-theme="dark">
                    <Dropdown.Toggle id="masters_menu" variant="secondary">
                        <li className={"nav-item " + (this.state.route_master == true ?"active":"")}>
                            <a className="nav-link" href="#">
                                <FontAwesomeIcon icon={icon({ name: "list" })} />
                                <span>Masters</span>
                            </a>
                        </li>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="/masters/DeviceType">Device Type</Dropdown.Item>
                        <Dropdown.Item href="/masters/DeviceBrand">Brand</Dropdown.Item>
                        <Dropdown.Item href="/masters/DeviceModel">Device Model</Dropdown.Item>
                        <Dropdown.Item href="/masters/CoverageMaster">Coverage</Dropdown.Item>
                        {/* <Dropdown.Item href="/masters/question">Question</Dropdown.Item> */}
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown data-bs-theme="dark">
                    <Dropdown.Toggle id="app_settings" variant="secondary">
                        <li className={"nav-item " + (this.state.route_settings == true ?"active":"")}>
                            <a className="nav-link" href="#">
                                <FontAwesomeIcon icon={icon({ name: "cog" })} />
                                <span>Settings</span>
                            </a>
                        </li>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="/settings/AppSettings">App Settings</Dropdown.Item>
                        <Dropdown.Item href="/settings/WebSettings">Web Settings</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </ul>
        );
    }
}

export default Sidebar;
