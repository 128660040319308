import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/Auth/Login';
import Logout from './components/Auth/Logout';
import AdminLayout from './components/Layout/Admin'
import PageNotFound from './components/Layout/PageNotFound'
import Dashboard from './components/Dashboard/Dashboard';
import Insurance from './components/Insurance/Insurance';
import Master from './components/Masters/Master';
import Setting from './components/Settings/Setting';
import Claim from './components/Claim/Claim';
import ClaimDetails from './components/Claim/ClaimDetails';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/sb-admin-2.css'


class App extends Component {
  constructor(props) {
    super(props);
    // user_Authentication
    let userId = localStorage.getItem("userId");
    let accessToken = localStorage.getItem("access_token");

    this.state = {
      user_authentication: userId && accessToken ? true : false,
    };
    document.title = "Alya"

  }
  render() {
    return (
      <div>
        <Router>
          <Routes>
            <Route
              path="/login"
              element={this.state.user_authentication === true ? <Navigate to="/" /> : <Login/>}
            />
            <Route
              path="/logout"
              element={<Logout />}
            />
            <Route
              path="/"
              element={this.state.user_authentication === true ? <AdminLayout><Dashboard /></AdminLayout> : <Navigate to="/login" />}
            />
            <Route
              path="/insurance"
              element={this.state.user_authentication === true ? <AdminLayout><Insurance /></AdminLayout> : <Navigate to="/login" />}
            />
            <Route
              path="/claim"
              element={this.state.user_authentication === true ? <AdminLayout><Claim /></AdminLayout> : <Navigate to="/login" />}
            />
            <Route
              path="/claim/:id"
              element={this.state.user_authentication === true ? <AdminLayout><ClaimDetails /></AdminLayout> : <Navigate to="/login" />}
            />
            <Route
              path="/settings/:name"
              element={this.state.user_authentication === true ? <AdminLayout><Setting /></AdminLayout> : <Navigate to="/login" />}
            />
            <Route
              path="/masters/:name"
              element={this.state.user_authentication === true ? <AdminLayout><Master /></AdminLayout> : <Navigate to="/login" />}
            />
            <Route path="*" element={<PageNotFound />} />

          </Routes>
        </Router>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme="light"
        />
      </div>
    );
  }
}

export default App;
