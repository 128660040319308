import React, { Component } from "react";

class Logout extends Component {
  componentDidMount() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userData");
    window.location = "/";
  }
  render() {
    return null;
  }
}

export default Logout;
