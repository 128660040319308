import React, { useState } from 'react';
import api from '../../helper/Api';
import { toast } from 'react-toastify';
import '../../assets/styles/login.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

function Login({ }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    if (email == '') {
      toast.error("Please Enter Email!");
      return false;
    }
    if (password == '') {
      toast.error("Please Enter Password!");
      return false;
    }
    api.post("login", { email, password })
      .then(response => {
        try {
          console.log(response);
          if (response.data.error) {
            toast.error(response.data.message)
          } else {
            localStorage.setItem('userData', JSON.stringify(response.data.user));
            localStorage.setItem('userId', (response.data.user.id));
            localStorage.setItem('access_token', (response.data.access_token));
            window.location = "/";
          }
        } catch (error) {
          console.error(error);
        }
      }
    ) 
  };

  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-md-4 login-div">
          <div className="card shadow">
            <div className="card-header text-center"><h3>Sign In</h3></div>
            <div className="card-body">
              <form>
                <div className="form-group mb-4 mt-2">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={icon({name: "user"})} /></span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group mb-4">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={icon({name: "Lock"})} size="1rem"/></span>
                    </div>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className='text-center'>
                  <button type="button" className="btn btn-primary login-btn" onClick={handleLogin}>
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
