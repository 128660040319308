import React, { Component } from "react";

import Sidebar from "./Sidebar/Sidebar";
import Header from "./Headers/Header";
import Footer from "./Footers/Footer";


class AdminLayout extends Component {
  constructor(props) {
    super(props);

    // define states
  }

  render() {
    return (
        <div className="wrapper">
            <div id="wrapper">
                <Sidebar {...this.props.children.props} />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content" style={{marginBottom: "68px"}}>
                    <Header/>
                    {React.cloneElement(this.props.children)}
                    <Footer />
                  </div>
                </div>
            </div>
        </div>
    );
  }
}
export default AdminLayout;
