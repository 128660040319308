import React, { Component } from "react";
import undraw_profile from '../../../assets/images/undraw_profile.svg'
import Dropdown from 'react-bootstrap/Dropdown';
import api from '../../../helper/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

class Header extends Component {
    constructor(props) {
        super(props);
        var user = JSON.parse(localStorage.getItem('userData'));
        this.state = {
            user: user,
            company_balance: 0,
        };
    }
    showHideDropdown = () => {
        this.setState({profileDropdown : true}) 
    }
    componentDidMount() {
        var context = this;
        api.get("dashboard")
        .then(response => {
            try {
            if (response.data.error) {
            } else {
                var dashboard_counts = response.data.counts;
                var company_balance = 0;
                for (let index = 0; index < dashboard_counts.length; index++) {
                if (dashboard_counts[index]["name"] == "Cover Amount") {
                    company_balance = dashboard_counts[index]["count"].replace("Rs. ", "");
                }
                }
                context.setState({company_balance:company_balance });
            }
            } catch (error) {
            }
        });
    }

    render() {
        return (
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown no-arrow mx-1">
                        <a className="nav-link dropdown-toggle" style={{color: "black"}}>
                            <FontAwesomeIcon icon={icon({name: "wallet"})} />
                            <span style={{marginLeft: 6}}>Rs. {this.state.company_balance}</span>
                        </a>
                    </li>
                    <li className="nav-item dropdown no-arrow">
                        <Dropdown data-bs-theme="dark">
                            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">{this.state.user.first_name} {this.state.user.last_name}</span>
                                                    <img className="img-profile rounded-circle"
                                                        src={undraw_profile} />
                                                </a>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="/Logout">Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default Header;
