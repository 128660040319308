import React, { Component } from "react";

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <footer className="sticky-footer bg-white" style={{ position: "fixed", bottom: "0px", width: "-webkit-fill-available" }}>
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright &copy; Your Website 2023</span>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
