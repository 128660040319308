import React, { Component } from "react";
import api from '../../helper/Api';
import { toast } from 'react-toastify';
import Loading from 'react-fullscreen-loading';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Moment from 'react-moment';
import moment from 'moment';

const sortIcon = <ArrowDownward />;
const customStyles = {
    rows: {
        style: {
        },
    },
    headCells: {
        style: {
          backgroundColor: "#dbdbdb",
          fontWeight: "bold",
        },
    },
    cells: {
        style: {
        },
    },
};
class Insurance extends Component {
  constructor(props) {
    super(props);
    const columns = [
        {
            name: 'Sr. No.',
            selector: row => row.id,
            sortable: true,
            maxWidth: '100px',
            wrap: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Mobile No',
            selector: row => row.mobile_no,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Device',
            selector: row => row.device_name,
            sortable: true,
            wrap: true,
            minWidth: '150px',
        },
        // {
        //     name: 'Brand',
        //     selector: row => row.brand_name,
        //     sortable: true,
        //     wrap: true,
        // },
        // {
        //     name: 'Device Purchase Date',
        //     selector: row => row.device_purchase_date,
        //     sortable: true,
        //     minWidth: '180px',
        //     wrap: true,
        // },
        // {
        //     name: 'Product Amount',
        //     selector: row => row.product_amount,
        //     minWidth: '150px',
        //     sortable: true,
        // },
        {
            name: 'Insurance Amount',
            selector: row => row.insurance_amount,
            minWidth: '160px',
            sortable: true,
        },
        // {
        //     name: 'Company Amount',
        //     selector: row => row.company_amount,
        //     minWidth: '160px',
        //     sortable: true,
        // },
        {
            name: 'Status',
            selector: row => row.status_name,
            // sortable: true,
        },
        {
            name: 'Expiry Date',
            selector: row => row.expiry_date,
            minWidth: '160px',
            // sortable: true,
        },
    ];
    console.log(columns)
    this.state = {
      loading: true,
      user: JSON.parse(localStorage.getItem('userData')),
      status: '2',
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      columns: columns,
      insurance_data: [],

    }
  }
  componentDidMount() {
    this.callApi();
  }
  handleChange = ({ currentTarget: input }) => {
    this.setState({ 
      [input.name]:input.value
     },() => {
        this.callApi()
    });
  }
  callApi = () => {
    var context = this;
    context.setState({ loading: true });
    api.get("insurance",{start_date:context.state.start_date,end_date:context.state.end_date,status:context.state.status})
      .then(response => {
        try {
          if (response.data.error) {
            toast.error(response.data.message)
          } else {
            var insurance_data = [];
            for (let index = 0; index < response.data.data.length; index++) {
              const element = response.data.data[index];
              var status_name = "Valid";
              var status_class_name = "success";
              if (element.status == 1) {
                status_name = "Payment Pending";
                status_class_name = "primary";
              } else if (element.status == 3) {
                status_name = "Claimed";
                status_class_name = "primary";
              } else if (element.status == 4) {
                status_name = "Expired";
                status_class_name = "danger";
              }
              insurance_data.push({
                id: index + 1,
                name: element.first_name + " " + element.last_name,
                mobile_no: element.mobile_no,
                device_name: element.device_model.name,
                brand_name: element.device_model.device_brand.name,
                device_purchase_date: element.purchase_date,
                product_amount: element.product_amount,
                insurance_amount: "Rs. "+element.user_amount,
                company_amount: element.company_amount,
                status_name: <span className={`badge badge-pill badge-${status_class_name}`} style={{fontSize:"100%",padding: "0.5em 1em"}}>{status_name}</span>,
                expiry_date: <Moment format="DD-MM-YYYY">{element.expiry_date}</Moment>,
              })
            }
            console.log(insurance_data)
            context.setState({ insurance_data: insurance_data, loading: false });
          }
        } catch (error) {
        }
      });
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
          <h1 className="h3 m-0 font-weight-bold text-primary">Insurance</h1>
          </div>
          <div className="card-body">
            <div className="row p-1 g-3 align_item_center">
              <div className="col-3">
                  <label for="start_date" className="form-label">Start Date</label>
                  <input type="date" className="form-control" id="start_date" name="start_date" placeholder="" value={this.state.start_date} onChange={this.handleChange} />
              </div>
              <div className="col-3">
                  <label for="end_date" className="form-label">End Date</label>
                  <input type="date" className="form-control" id="end_date" name="end_date" placeholder="" value={this.state.end_date} onChange={this.handleChange} />
              </div>
              <div className="col-3">
                  <label for="status" className="form-label">Status</label>
                  <select
                    className="form-control"
                    id="status"
                    name="status"
                    onChange={this.handleChange}
                    value={this.state.status}
                  >
                      <option value="1">Payment Pending</option>
                      <option value="2">Valid</option>
                      <option value="3">Claimed</option>
                      <option value="4">Expired</option>
                  </select>
              </div>
            </div>
            <br/>
            <div className="table-responsive">
              <DataTable
                pagination
                responsive
                sortIcon={sortIcon}
                columns={this.state.columns}
                data={this.state.insurance_data}
                defaultSortFieldId={1}
                striped
                customStyles={customStyles}
              />
            </div>
          </div>
        </div>
        { this.state.loading?<Loading loading background="#ffffff80" loaderColor="rgb(76 175 80)" />:null}
      </div>
    );
  }
}

export default Insurance;
