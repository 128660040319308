import React, { Component } from "react";
import api from '../../helper/Api';
import { toast } from 'react-toastify';
import Loading from 'react-fullscreen-loading';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const sortIcon = <ArrowDownward />;
const customStyles = {
    rows: {
        style: {
        },
    },
    headCells: {
        style: {
          backgroundColor: "#dbdbdb",
          fontWeight: "bold",
        },
    },
    cells: {
        style: {
        },
    },
};
class Claim extends Component {
  constructor(props) {
    super(props);
    const columns = [
        {
            name: 'Sr. No.',
            selector: row => row.id,
            sortable: true,
            maxWidth: '100px',
            wrap: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Mobile No',
            selector: row => row.mobile_no,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Device',
            selector: row => row.device_name,
            sortable: true,
            wrap: true,
            minWidth: '150px',
        },
        {
            name: 'Coverage',
            selector: row => row.coverage,
            sortable: true,
            wrap: true,
            minWidth: '150px',
        },
        {
            name: 'Request Amount',
            selector: row => row.req_amount,
            minWidth: '160px',
            sortable: true,
        },
        {
            name: 'Vote Amount',
            selector: row => row.vote_amount,
            minWidth: '160px',
            sortable: true,
        },
        {
            name: 'Approved Amount',
            selector: row => row.claim_amount,
            minWidth: '160px',
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status_name,
            minWidth: '220px',
        },
        {
            name: 'Action',
            selector: row => row.actions,
            minWidth: '40px',
        },
    ];
    console.log(columns)
    this.state = {
      loading: true,
      user: JSON.parse(localStorage.getItem('userData')),
      status: '0',
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      columns: columns,
      insurance_data: [],

    }
  }
  componentDidMount() {
    this.callApi();
  }
  handleChange = ({ currentTarget: input }) => {
    this.setState({ 
      [input.name]:input.value
     },() => {
        this.callApi()
    });
  }
  callApi = () => {
    var context = this;
    context.setState({ loading: true });
    api.get("claim",{start_date:context.state.start_date,end_date:context.state.end_date,status:context.state.status})
      .then(response => {
        try {
          if (response.data.error) {
            toast.error(response.data.message)
          } else {
            var insurance_data = [];
            for (let index = 0; index < response.data.data.length; index++) {
              const element = response.data.data[index];
              var status_class_name = "success";
              if (element.status == 0 || element.status == 1 || element.status == 2 || element.status == 3) {
                status_class_name = "primary";
              } else if (element.status == 5 || element.status == 6) {
                status_class_name = "danger";
              }
              insurance_data.push({
                id: index + 1,
                name: element.insurance.first_name + " " + element.insurance.last_name,
                mobile_no: element.insurance.mobile_no,
                device_name: element.insurance.device_model.name,
                req_amount: "Rs. "+element.req_amount,
                vote_amount: (element.vote_amount?"Rs. "+element.vote_amount:""),
                claim_amount: (element.claim_amount?"Rs. "+element.claim_amount:""),
                insurance_amount: "Rs. "+element.insurance.user_amount,
                company_amount: element.insurance.company_amount,
                coverage: element.coverage_type.name,
                status_name: <span className={`badge badge-pill badge-${status_class_name}`} style={{ fontSize: "100%", padding: "0.5em 1em" }}>{element.status_name}</span>,
                actions: <a href={`/claim/${element.id}`} className="btn btn-light btn-circle"><FontAwesomeIcon icon={icon({name: "eye"})} /></a>,
              })
            }
            console.log(insurance_data)
            context.setState({ insurance_data: insurance_data, loading: false });
          }
        } catch (error) {
        }
      });
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
          <h1 className="h3 m-0 font-weight-bold text-primary">Claim</h1>
          </div>
          <div className="card-body">
            <div className="row p-1 g-3 align_item_center">
              <div className="col-3">
                  <label for="start_date" className="form-label">Start Date</label>
                  <input type="date" className="form-control" id="start_date" name="start_date" placeholder="" value={this.state.start_date} onChange={this.handleChange} />
              </div>
              <div className="col-3">
                  <label for="end_date" className="form-label">End Date</label>
                  <input type="date" className="form-control" id="end_date" name="end_date" placeholder="" value={this.state.end_date} onChange={this.handleChange} />
              </div>
              <div className="col-3">
                  <label for="status" className="form-label">Status</label>
                  <select
                    className="form-control"
                    id="status"
                    name="status"
                    onChange={this.handleChange}
                    value={this.state.status}
                  >
                      <option value="0">Request Under Process</option>
                      <option value="1">Voting Under Process</option>
                      <option value="2">Verify Bank Details</option>
                      <option value="3">Payment Under Process</option>
                      <option value="4">Payment Done</option>
                      <option value="5">Claim Rejected By Admin</option>
                      <option value="6">Claim Rejected By User</option>
                  </select>
              </div>
            </div>
            <br/>
            <div className="table-responsive">
              <DataTable
                pagination
                responsive
                sortIcon={sortIcon}
                columns={this.state.columns}
                data={this.state.insurance_data}
                defaultSortFieldId={1}
                striped
                customStyles={customStyles}
              />
            </div>
          </div>
        </div>
        { this.state.loading?<Loading loading background="#ffffff80" loaderColor="rgb(76 175 80)" />:null}
      </div>
    );
  }
}

export default Claim;
