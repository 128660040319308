import React, { Component } from "react";
import api from '../../helper/Api';
import { toast } from 'react-toastify';
import Loading from 'react-fullscreen-loading';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useParams } from "react-router-dom";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // maxWidth: 400,
    width: "60%",
    minWidth: 400,
    bgcolor: 'background.paper',
    // border: '2px solid rgb(1, 46, 93)',
    border: 'none',
    borderRadius: '12px',
    boxShadow: 24,
};
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class ClaimDetails extends Component {
  constructor(props) {
      super(props);
      this.state = {
          openVoteAmount: false,
          loading: true,
          status: 0,
          vote_result: 0,
          company_balance: 0,
          reason: '',
          user: JSON.parse(localStorage.getItem('userData')),
          claim_id: this.props.params.id,
          insurance_data: {},
          vote_amount: 0,
          voter_data: {
            labels: [],
            datasets: [
              {
                label: '# of Votes',
                data: [],
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
              },
            ],
          },
      }
  }
  componentDidMount() {
    this.callApi();
  }
  callApi = () => {
    var context = this;
    context.setState({ loading: true });
    api.get("claim",{claim_id:context.state.claim_id})
      .then(response => {
        try {
          if (response.data.error) {
            toast.error(response.data.message)
          } else {
              if (response.data.data.length > 0) {
                  var insurance_data = response.data.data[0];
                  var status_class_name = "success";
                  if (insurance_data.status == 0 || insurance_data.status == 1 || insurance_data.status == 2 || insurance_data.status == 3) {
                    status_class_name = "primary";
                  } else if (insurance_data.status == 5 || insurance_data.status == 6) {
                    status_class_name = "danger";
                  }
                if (insurance_data.product_image)
                  insurance_data.product_image = insurance_data.product_image.split(',');
                if (insurance_data.files)
                  insurance_data.files = insurance_data.files.split(',');
                if (insurance_data.fir)
                  insurance_data.fir = insurance_data.fir.split(',');
                  context.setState({ insurance_data: insurance_data, status_class_name:status_class_name, loading: false });
              }
          }
        } catch (error) {
        }
      });
      api.get("dashboard")
      .then(response => {
        try {
          if (response.data.error) {
            toast.error(response.data.message)
          } else {
            var dashboard_counts = response.data.counts;
            var company_balance = 0;
            for (let index = 0; index < dashboard_counts.length; index++) {
              if (dashboard_counts[index]["name"] == "Cover Amount") {
                company_balance = dashboard_counts[index]["count"].replace("Rs. ", "");
              }
            }
            context.setState({company_balance:company_balance });
          }
        } catch (error) {
        }
      });
      api.get("vote",{claim_id:this.state.claim_id})
      .then(response => {
        try {
          if (response.data.error) {
            toast.error(response.data.message)
          } else {
            var voter_result = context.state.voter_data;
            voter_result.labels = [];
            voter_result.datasets[0].data = [];
            for (let index = 0; index < response.data.data.length; index++) {
              const element = response.data.data[index];
              voter_result.labels.push(element['vote_amount']);
              voter_result.datasets[0].data.push(element['vote']);
            }
            if (voter_result.labels.length > 0) {
              context.setState({ voter_data: voter_result,vote_result:voter_result.labels[0] });
            }
          }
        } catch (error) {
        }
      });
  }

  handleOpen = (status) => {
      this.setState({openVoteAmount:true,status:status})
  }
  handleClose = () => {
      this.setState({openVoteAmount:false})
  }
  handleChange = ({ currentTarget: input }) => {
      this.setState({ [input.name]:input.value },() => {
      });
  };
  submitForm = () => {
    var context = this;
    if (context.state.status == 1) {
      if (context.state.vote_amount == '' || context.state.vote_amount == 0) {
        toast.error("Please Enter Vote Amount!");
        return false;
      }
      if (context.state.vote_amount > context.state.insurance_data.req_amount) {
        toast.error("Please Enter Vote Amount less than or equal to requested amount!");
        return false;
      }
    } else if (context.state.status == 5) {
      if (context.state.reason == '') {
        toast.error("Please Enter Reason!");
        return false;
      }
    } else if (context.state.status == 4) {
      if (context.state.claim_amount == '' || context.state.claim_amount == 0) {
        toast.error("Please Enter Claim Amount!");
        return false;
      }
      if (context.state.claim_amount > context.state.vote_result) {
        toast.error("Please Enter Claim Amount less than or equal to vote result amount!");
        return false;
      }
      // console.log(context.state.claim_amount > context.state.company_balance)
      if (parseFloat(context.state.claim_amount) > parseFloat(context.state.company_balance)) {
        toast.error("Please Enter Claim Amount less than or equal to company funds!");
        return false;
      }
    }
    context.setState({ loading: true });
    var data = {};
    data.status = context.state.status;
    data.claim_id = context.state.claim_id;
    if (context.state.status == 1) {
      data.vote_amount = context.state.vote_amount;
    }
    if (context.state.status == 5) {
      data.reason = context.state.reason;
    }
    if (context.state.status == 4) {
      data.claim_amount = context.state.claim_amount;
      data.claim_date = moment().format('YYYY-MM-DD');
    }
    
    api.post("claim/update_status",data)
      .then(response => {
        try {
          if (response.data.error) {
            toast.error(response.data.message);
          } else {
            toast.success(response.data.message);
            context.setState({ loading: true, openVoteAmount: false });
            this.callApi();
          }
        } catch (error) {
        }
      });
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <div className="row">
              <h1 className="h3 m-0 font-weight-bold text-primary col">Claim Details</h1>
              <span className={`badge badge-pill badge-${this.state.status_class_name}`} style={{ fontSize: "100%", padding: "0.5em 1em" }}>{this.state.loading ? '':this.state.insurance_data.status_name}</span>
            </div>
          </div>
          {this.state.loading ? <div className="card-body"></div> :
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <p><b>User Name:-</b> {this.state.insurance_data.insurance.first_name + " " + this.state.insurance_data.insurance.last_name}</p>
                </div>
                <div className="col">
                  <p><b>Mobile No:-</b> {this.state.insurance_data.insurance.mobile_no}</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p><b>Device Name:-</b> {this.state.insurance_data.insurance.device_model.name}</p>
                </div>
                <div className="col">
                  <p><b>Device Brand:-</b> {this.state.insurance_data.insurance.device_model.device_brand.name}</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p><b>Coverage:-</b> {this.state.insurance_data.coverage_type.name}</p>
                </div>
                <div className="col">
                  <p><b>Request Amount:-</b> Rs. {this.state.insurance_data.req_amount}</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p><b>Vote Amount:-</b> {this.state.insurance_data.vote_amount ? "Rs. " + this.state.insurance_data.vote_amount : ""}</p>
                </div>
                <div className="col">
                  <p><b>Approved Amount:-</b> {this.state.insurance_data.claim_amount ? "Rs. " + this.state.insurance_data.claim_amount : ""}</p>
                </div>
              </div>
              {this.state.insurance_data.reason ?
                <div className="row">
                  <div className="col">
                    <p><b>Rejection Reason:-</b> {this.state.insurance_data.reason}</p>
                  </div>
                </div>
                : ''}
              {this.state.insurance_data.product_image && this.state.insurance_data.product_image != '' ? 
                <div className="mb-2">
                  <div className="row">
                    <div className="col">
                      <p><b>Uploaded Product Images:-</b></p>
                    </div>
                  </div>
                  <div className="row">
                    {
                      this.state.insurance_data.product_image.map(product_image => (
                        <div className="col">
                          <img src={product_image} style={{width:"100%", maxWidth: "200px"}} />
                        </div>
                      ))
                    }
                  </div>
                </div>
                : ''}
              {this.state.insurance_data.files && this.state.insurance_data.files != '' ? 
                <div className="mb-2">
                  <div className="row">
                    <div className="col">
                      <p><b>Quotations:-</b></p>
                    </div>
                  </div>
                  <div className="row">
                    {
                      this.state.insurance_data.files.map(files => (
                        <div className="col">
                          <img src={files} style={{width:"100%", maxWidth: "200px"}} />
                        </div>
                      ))
                    }
                  </div>
                </div>
                : ''}
              {this.state.insurance_data.fir && this.state.insurance_data.fir != '' ? 
                <div className="mb-2">
                  <div className="row">
                    <div className="col">
                      <p><b>FIR:-</b></p>
                    </div>
                  </div>
                  <div className="row">
                    {
                      this.state.insurance_data.fir.map(fir => (
                        <div className="col-3">
                          <img src={fir} style={{width:"100%", maxWidth: "200px"}} />
                        </div>
                      ))
                    }
                  </div>
                </div>
                : ''}
                <div className="card shadow mt-4">
                  <div className="card-header py-3">
                    <div className="row">
                      <h2 className="h4 m-0 font-weight-bold text-primary">Bank Details</h2>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <p><b>Account Holder Name:-</b> {this.state.insurance_data.insurance.account_holder_name}</p>
                      </div>
                      <div className="col">
                        <p><b>Account Number:-</b> {this.state.insurance_data.insurance.account_number}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <p><b>IFSC Code:-</b> {this.state.insurance_data.insurance.ifsc_code}</p>
                      </div>
                    </div>
                  </div>
                </div>
              {this.state.insurance_data.status != 0 && this.state.insurance_data.status != 5 && this.state.voter_data.labels.length>0 ?
                  <div className="card shadow mt-4">
                    <div className="card-header py-3">
                      <div className="row">
                        <h2 className="h4 m-0 font-weight-bold text-primary">Vote Result</h2>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row" style={{ marginTop: "40px" }}>
                        <div className="col"  style={{ maxHeight: 400 }}>
                        <Pie data={this.state.voter_data} redraw={true} width={"80%"}
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                : ''}
                {this.state.insurance_data.status == 0 ? 
                  <div className="row" style={{ marginTop: "40px", background: "#f8f9fc", paddingTop: "18px", paddingBottom: "18px", textAlign: "center" }}>
                    <div className="col">
                      <a className="btn btn-success btn-icon-split" onClick={() => this.handleOpen(1)}>
                          <span className="icon text-white-50">
                              <FontAwesomeIcon icon={icon({name: "check"})} />
                          </span>
                          <span className="text">Approve Request</span>
                      </a>
                      <a className="btn btn-danger btn-icon-split" onClick={() => this.handleOpen(5)} style={{marginLeft:10}}>
                          <span className="icon text-white-50">
                              <FontAwesomeIcon icon={icon({name: "exclamation"})} />
                          </span>
                          <span className="text">Reject Request</span>
                      </a>
                    </div>
                  </div>
                : ''}
                {this.state.insurance_data.status == 3 ? 
                  <div className="row" style={{ marginTop: "40px", background: "#f8f9fc", paddingTop: "18px", paddingBottom: "18px", textAlign: "center" }}>
                    <div className="col">
                      <a className="btn btn-success btn-icon-split" onClick={() => this.handleOpen(4)}>
                          <span className="icon text-white-50">
                              <FontAwesomeIcon icon={icon({name: "check"})} />
                          </span>
                          <span className="text">Process Payment</span>
                      </a>
                    </div>
                  </div>
                  :''}
              </div>
           }
        </div>
        <Modal
          open={this.state.openVoteAmount}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{zIndex:1}}
        >
          <Box sx={style}>
            <div style={{ padding: "10px", borderBottom: "1px solid rgb(1, 46, 93)" }}>
                <div className="d-flex">
                  <h5 style={{ flex: 1, marginBottom: "0px", color: "rgb(1, 46, 93)", textAlign: "center" }}>
                  {this.state.status == 1 ? "Approve Request" : (this.state.status == 5 ? 'Reject Request' : (this.state.status == 4 ? 'Final Payment' : ''))}
                  </h5>
                  <button style={{ background: "none", border: "none", width: "fit-content", padding: "0px", color: "red" }} onClick={this.handleClose}>
                      <img style={{width: "24px"}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVR4nO2Z3UrDMBTH81Amft01012oCOKt+BAqKA4miCJ44Y0PIIj4id6IdzrfwyFDbzargjYbrZVFWtexuXY2TZN0kD+cu5X9fzkn56QNAFpaWlqZVh2jWWKge8tAhGBEZYTl/ZcBS3VjaIbLvIXRjizTJAoGw63kK6/YPGkHnGYG8MomQwB37AAYWhkC+EwAoNo06goNQKJWJz9GSW54QDOQH6ffN9fUPT1ihrALK36oA2iZp+a7HywQ9voSpVWT0tortTfW1AC4Z8dt80F8HR78C9E2HzxXiweROkBjYY42H8o9EP0y0WPebAEUV9XsARYIHvNEZBuNA8FrnoieA/0g7MIyt3kiY5BFQdCXN27zRNYkjoTgNE9kHiV8iMpTaCbsYryeT1QC+Bu29qfmgz1xwj6xiUyA0G6TEgQQDRDVKpuV51QggEiAfn0+ssUyQgBRAHGGVBoQQAQAy4TlhQBpAyQ5HjQW52mz/Bh+isWSAdyri24jVTPWC0pYJpz9PQUlNDFC3cvzRBO2E8KJYV7cJp4c9TPB8mrYWU7O7nbs34sBkBhAA+CBywDMzqdFA36wAxiwpNw4DgLeMgN4lwvqjaPfyKEpkETe5YJq85aBNgGPvMsF7/u83D0BLa9sEq+8lpaWFpClH85QF54vuUIVAAAAAElFTkSuQmCC"/>
                  </button>
                </div>
            </div>
            <main className="container" style={{ maxWidth: "100%", marginTop: 10, marginBottom: 10 }}>
              {this.state.status == 1 ?
                <div className="row p-1 g-3 align_item_center">
                  <label for="vote_amount" className="form-label">Amount for Voting</label>
                  <input type="number" style={{ marginLeft: 10 }} className="form-control col" id="vote_amount" name="vote_amount" placeholder="" value={this.state.vote_amount} onChange={this.handleChange} />
                </div>
                : ''}
              {this.state.status == 5 ?
                <div className="row p-1 g-3 align_item_center">
                  <label for="reason" className="form-label">Reason</label>
                  <input type="text" style={{ marginLeft: 10 }} className="form-control col" id="reason" name="reason" placeholder="" value={this.state.reason} onChange={this.handleChange} />
                </div>
                : ''}
              {this.state.status == 4 ?
                <div className="row p-1 g-3 align_item_center">
                  <label for="claim_amount" className="form-label">Claim Amount</label>
                  <input type="number" style={{ marginLeft: 10 }} className="form-control col" id="claim_amount" name="claim_amount" placeholder="" value={this.state.claim_amount} onChange={this.handleChange} />
                </div>
                : ''}
              <div className="row pb-0 align_item_center" style={{justifyContent: "center", marginTop: 10}}>
                  <button className="btn btn-primary btn-lg" style={{ width: "fit-content", borderRadius: "24px", padding: "12px 26px", color: "white", background: "#4caf50", border: "white", fontWeight: 700, fontSize: "13px" }}
                      onClick={ this.submitForm }>Submit</button>
              </div>
            </main>
          </Box>
        </Modal>
        {this.state.loading ? <Loading loading background="#ffffff80" loaderColor="rgb(76 175 80)" /> : null}
      </div>
    );
  }
}

export default withParams(ClaimDetails);
